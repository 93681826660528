:root {
    --contact-header-color: rgb(126, 255, 255);
    --contact-subheader-color: rgb(255, 255, 255);
    --contact-link-color: rgb(252, 154, 84);
    --contact-input-bg:  rgb(97, 97, 97);
    --contact-input-border: rgb(151, 151, 151);
    --contact-button: rgb(97, 97, 97);
    --contact-button-border: rgba(126, 255, 255, 0.5);
    --contact-button-color: rgb(126, 255, 255);
}

/* Mobile CSS */
@media only screen and (max-width: 600px) {
    .under-maintance {
        color: red;
        font-size: small;
    }
    
    .contact {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: center;
    
        margin: 5vh 5vw;
        min-height: 80vh;
    
        font-family: "Exo 2";
        font-style: italic;
    }
    
    .contact-header {
        color: var(--contact-header-color);
        align-self: flex-start;
        font-size: 1.8em;
    }
    
    .info-list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        align-content: center;
        padding: 0;
        list-style-position: inside;
    }
    
    .info-header {
        color: var(--contact-subheader-color);
        align-self: flex-start;
        font-size: 1.4em;
    }
    
    .info-link {
        color: var(--contact-link-color);
        text-decoration: none;
        opacity: .75;
        margin-bottom: 1em;
        font-size: 1.1em;
        z-index: -1;
    }
    
    .info-link:hover {
        color: var(--contact-link-color);
        text-decoration: none;
        opacity: 1;
    }
    
    .info-list-item {
    
    }
    
    .contact-form {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        align-content: center;
    
        width: 100%;
        margin-top: 1.5em;
    }
    
    .form-label {
        color: var(--contact-subheader-color);
        font-size: 1.1em;
        align-self: flex-start;
        margin: 0;
    }
    
    .form-input {
        background-color: var(--contact-input-bg);
        border: 1px solid var(--contact-input-border);
        color: var(--contact-subheader-color);
        font-size: 1.1em;
        border-radius: .25em;
        width: 100%;
        height: 2em;
        max-width: 800px;
        padding: 2px 5px;
    }

    .message-input {
        height: 8em;
    }
    
    .form-button {
        max-width: 300px;
        width: 8em;
        
        border-radius: .25em;
    
        background-color: var(--contact-button);
        border: 1.5px solid var(--contact-button-border);
        color: var(--contact-button-color);
        
        margin-top: 1em;
        font-family: "Exo 2";
        font-style: italic;
        text-align: center;
        padding: 0;
        font-size: 1.2em;
    }
}

/* Desktop CSS */
@media only screen and (min-width: 601px) {
    .under-maintance {
        color: red;
        font-size: small;
    }
    
    .contact {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: center;
    
        margin: 5vh auto;
        min-height: 80vh;
        width: 70%;
        max-width: 1000px;
    
        font-family: "Exo 2";
        font-style: italic;
    }
    
    .contact-header {
        color: var(--contact-header-color);
        align-self: flex-start;
        font-size: 2.5em;
    }
    
    .info-list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        align-content: center;
        padding: 0;
        list-style-position: inside;
    }
    
    .info-header {
        color: var(--contact-subheader-color);
        align-self: flex-start;
        font-size: 1.8em;
    }
    
    .info-link {
        color: var(--contact-link-color);
        text-decoration: none;
        opacity: .75;
        margin-bottom: 1em;
        font-size: 1.4em;
        z-index: -1;
    }
    
    .info-link:hover {
        color: var(--contact-link-color);
        text-decoration: none;
        opacity: 1;
    }
    
    .info-list-item {
    
    }
    
    .contact-form {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        align-content: center;
    
        width: 100%;
        margin-top: 1.5em;
    }
    
    .form-label {
        color: var(--contact-subheader-color);
        font-size: 1.5em;
        align-self: flex-start;
        margin: 0;
    }
    
    .form-input {
        background-color: var(--contact-input-bg);
        border: 1px solid var(--contact-input-border);
        color: var(--contact-subheader-color);
        font-size: 1.1em;
        border-radius: .25em;
        width: 100%;
        height: 3em;
        max-width: 800px;
        padding: 2px 5px;
    }

    .message-input {
        height: 15em;
    }
    
    .form-button {
        max-width: 300px;
        width: 10em;
        
        border-radius: .25em;
    
        background-color: var(--contact-button);
        border: 1.5px solid var(--contact-button-border);
        color: var(--contact-button-color);
        
        margin-top: 1em;
        font-family: "Exo 2";
        font-style: italic;
        text-align: center;
        padding: 0;
        font-size: 1.5em;
    }
} 

