:root {
  --h1-color: rgb(168, 255, 126);
  --span-1-color: rgb(42, 87, 245);
  --span-2-color: rgb(126, 255, 255);
  --span-3-color: rgb(252, 154, 84);
  --span-4-color: rgb(255, 255, 255);
  --home-button: rgb(48, 48, 48);
  --home-button-border: rgb(0, 0, 0);
  --home-button-text: rgb(126, 255, 255);
  --pulse-color: rgb(126, 255, 255, .5);
}

/* Mobile CSS */
@media only screen and (max-width: 600px) {

}
/* Desktop CSS */
@media only screen and (min-width: 601px) {

} 

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

  height: 90vh;
}
.home * {
  /* margin: auto; */
}

.home-logo {
  max-width: 350px;
  border-radius: 50%;
  margin-top: auto;
}

.home-button {
  background-color: var(--home-button);
  border-color: var(--home-button-border);
  border-radius: 25px;
  width: 150px;
  height: 50px;
  margin-top: auto;


  text-decoration: none;
  color: var(--home-button-text);
  font-family: "Exo 2";
  font-style: italic;
  font-weight: bold;
  font-size: larger;
}

.pulse-effect {
  position: absolute;
  width: 150px;
  height: 50px;
  top: 0;
  border-radius: 25px;
  background-color: transparent;
  border: 1px solid var(--pulse-color);
  z-index: -1;
  animation: pulse 1200ms linear infinite;
}

@keyframes pulse {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1.3);
  }
}

.home-link {
  position: relative;
  background-color: var(--home-button);
  border-color: var(--home-button-border);
  border-radius: 25px;
  width: 150px;
  height: 50px;
  margin-top: auto;
}

.home-heading {
  font-family: "Exo 2";
  font-style: italic;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
}

.home-h1 {
  color: var(--h1-color);
  margin-right: 4em;
}

.home-h2 {
  margin-left: 3em;
}

.home-span-1 {
  color: var(--span-1-color);
}

.home-span-2 {
  color: var(--span-2-color);
}

.home-span-3 {
  color: var(--span-3-color);
}

.home-span-4 {
  color: var(--span-4-color);
}
