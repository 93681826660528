:root {
  --nav-bg: rgb(59, 60, 59);
  --nav-link-color: rgb(255, 255, 255);
  --mobile-nav-bg: hsl(0, 0%, 28%);
  --button-bg: rgb(41, 41, 41);
  --button-color: rgb(255, 255, 255);
  --button-border: rgb(0, 0, 0);
}

/* Mobile CSS */
@media only screen and (max-width: 600px) {
  .navbar-container {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: fixed;
    bottom: 0;
    height: 10vh;
    max-height: 6em;
    width: 100vw;

    background-color: var(--nav-bg);
  }

  .navbar {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    bottom: 0;
    height: 10vh;
    max-height: 6em;
    width: 100vw;

    background-color: var(--nav-bg);
  }

  .nav-button {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 0.25em;
    padding: 0;
    margin: 0;
    width: 3em;
    height: 3em;
    background-color: var(--button-bg);
    border-color: var(--button-border);
  }

  .nav-button-icon {
    color: var(--button-color);
    font-size: 2.25em;
  }

  .nav-link-list {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    position: absolute;
    bottom: 100%;
    width: 100%;
    z-index: -1;
    height: 40vh;
    padding: 0;
    margin: 0;
    background-color: var(--mobile-nav-bg);
  }

  .mobile-nav[data-visible="true"] {
    transform: translateY(0%);
  }

  .mobile-nav {
    transform: translateY(100%);
    transition: transform 500ms ease-out;
  }

  .nav-list-item {
    text-decoration: none;
    list-style: none;
    color: var(--nav-link-color);
    font-family: "Exo 2";
    font-style: italic;
    font-size: 1.2em;
  }

  hr{
    color: black;
    margin: 0;
    width: 100%;
  }
}

/* Desktop CSS */
@media only screen and (min-width: 601px) {
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 25%;
    min-width: 400px;
    margin-right: 5vw;
  }

  .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
  }

  .nav-button {
    display: none;
  }

  .nav-button-icon {
    display: none;
  }

  .nav-link-list {
    display: flex;
    flex-direction: row;
    flex-wrap: none;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .nav-link {
    text-decoration: none;
  }

  .nav-list-item {
    text-decoration: none;
    list-style: none;
    color: var(--nav-link-color);
    font-family: "Exo 2";
    font-style: italic;
    font-size: 1.5em;
  }
}