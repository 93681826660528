:root {
  --resume-header-color: rgb(126, 255, 255);
  --resume-sub-header-color: rgb(255, 255, 255);
  --resume-buttom-bg: rgb(126, 255, 255);
  --resume-list-item: rgb(255, 255, 255);
}

/* Mobile CSS */
@media only screen and (max-width: 600px) {
  .resume {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;

    margin: 5vh 5vw;
    min-height: 80vh;

    font-family: "Exo 2";
    font-style: italic;
  }

  .resume-header {
    color: var(--resume-header-color);
    align-self: flex-start;
    margin-top: 1.5em;
    font-size: 2em;
  }

  .resume-sub-header {
    color: var(--resume-sub-header-color);
    font-size: 1.5em;
    margin-top: 1em;
  }

  .resume-download {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    color: black;
    background-color: var(--resume-buttom-bg);
    border-radius: 0.5em;
    border: 1px solid black;
    padding: 3px;
  }

  .resume-resume {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 1em;
  }

  .resume-h4 {
    margin: 0;
    color: var(--resume-buttom-bg);
    font-size: 1.5em;
  }

  .resume-icon {
    color: black;
    font-size: 1.2em;
  }

  .resume-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    padding: 0;
    gap: 1em;
    margin: 2em;
  }

  .resume-list li {
    color: var(--resume-list-item);
    font-size: 1.2em;
    list-style-position: inside;
    flex-basis: 0;
    flex-grow: 1;
    min-width: calc(100vw / 3 - 1em);
    align-self: center;
  }
}

/* Desktop CSS */
@media only screen and (min-width: 601px) {
  .resume {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;

    margin: 5vh auto;
        min-height: 80vh;
        width: 70%;
        max-width: 1000px;

    font-family: "Exo 2";
    font-style: italic;
  }

  .resume-header {
    color: var(--resume-header-color);
    align-self: flex-start;
    margin-top: 3em;
    font-size: 2.5em;
  }

  .resume-sub-header {
    color: var(--resume-sub-header-color);
    font-size: 1.8em;
    margin-top: 1em;
  }

  .resume-download {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    color: black;
    background-color: var(--resume-buttom-bg);
    border-radius: 0.5em;
    border: 1px solid black;
    padding: 3px;
  }

  .resume-resume {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 1em;
  }

  .resume-h4 {
    margin: 0;
    color: var(--resume-buttom-bg);
    font-size: 1.8em;
  }

  .resume-icon {
    color: black;
    font-size: 1.4em;
  }

  .resume-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    padding: 0;
    gap: 1em;

    margin: 2em;
  }

  .resume-list li {
    color: var(--resume-list-item);
    font-size: 1.5em;
    list-style-position: inside;
    flex-basis: 0;
    flex-grow: 1;
    min-width: calc(1000px / 4 - 2em);
    align-self: center;
    margin: 1em auto;
  }
}
