:root {
  --project-border-color: rgb(215, 215, 215);
  --project-bg: rgb(71, 71, 71);
  --project-bg-active: rgb(97, 97, 97);
  --project-title-color: rgb(252, 154, 84);
  --project-border: rgb(151, 151, 151);
  --modal-bg: rgb(71, 71, 71);
  --modal-content-bg: rgb(41, 41, 41);
  --modal-title: rgb(252, 154, 84);
  --modal-subheader: rgb(126, 255, 255);
  --modal-icon: rgb(23, 21, 21);
  --modal-icon-bg: rgb(255, 255, 255);
  --modal-text: rgb(255, 255, 255);
}

.modal[data-visible="false"] {
  display: none;
}

.modal {
  display: block;
}

/* Mobile CSS */
@media only screen and (max-width: 600px) {
  .project {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    border: 2px solid var(--project-border);
    border-radius: 15%;

    width: 300px;
    height: 300px;
    margin: 2em 0;
    overflow: hidden;
    background-color: var(--project-bg);
  }

  .project:hover {
    background-color: var(--project-bg-active);
  }

  .project-image {
    width: 300px;
    top: 0;
    border-bottom: 2px solid var(--project-border);
  }

  .project-title {
    color: var(--project-title-color);
    text-align: center;
    margin: auto;
    font-size: 1.5em;
    width: 100%;
  }

  /* Modal section below! */
  .flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .modal {
    position: fixed;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: var(--modal-bg);
  }

  .modal-content {
    background-color: var(--modal-content-bg);
    width: 100%;
    height: 100%;
    margin: auto;
  }

  .modal-button {
    align-self: flex-end;
    background-color: var(--modal-content-bg);
    border: none;
    margin: 1em 0;
  }

  .modal-close-icon {
    color: var(--modal-icon-bg);
    font-size: 2em;
  }

  .carousel {
    width: 100%;
    max-width: 550px;
  }

  .modal-img {
    width: 100%;
  }

  .modal-info {
    width: 75%;
  }

  .modal-title {
    color: var(--modal-title);
    align-self: flex-start;
  }

  .modal-links {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    width: 100%;
  }

  .modal-link {
    text-decoration: none;
  }

  .modal-item {
    list-style: none;
    text-align: center;
  }

  .modal-icon {
    color: var(--modal-icon-bg);
    font-size: 5vh;
    text-align: center;
  }

  .modal-context {
    width: 100%;
  }

  .modal-subheader {
    color: var(--modal-subheader);
    align-self: flex-start;
  }

  .description {
    color: var(--modal-text);
    margin: 0;
    align-self: flex-start;
  }

  .tech-stack {
    align-self: flex-start;
    padding: 0;
  }

  .tech-item {
    color: var(--modal-subheader);
    list-style-position: inside;
    align-self: flex-start;
  }
}

/* Desktop CSS */
@media only screen and (min-width: 601px) {
  .project {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    border: 2px solid var(--project-border);
    border-radius: 15%;

    width: 300px;
    height: 300px;
    margin: 2em 0;
    overflow: hidden;
    background-color: var(--project-bg);
  }

  .project:hover {
    background-color: var(--project-bg-active);
  }

  .project-image {
    width: 300px;
    top: 0;
    border-bottom: 2px solid var(--project-border);
  }

  .project-title {
    color: var(--project-title-color);
    text-align: center;
    margin: auto;
    font-size: 1.5em;
    width: 100%;
  }

  /* Modal section below! */
  .flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .modal {
    position: fixed;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: var(--modal-bg);
  }

  .modal-content {
    background-color: var(--modal-content-bg);
    width: calc(100% - 1em);
    height: 100%;
    max-width: 1500px;
    margin: auto;
  }

  .modal-button {
    align-self: flex-end;
    background-color: var(--modal-content-bg);
    border: none;
    margin: 2em 3em 2em 0;
  }

  .modal-close-icon {
    color: var(--modal-icon-bg);
    font-size: 3em;
  }

  .carousel {
  }

  .modal-img {
    width: 100%;
    max-width: 1000px;
  }

  .modal-info {
    width: 75%;
    max-width: 1000px;
  }

  .modal-title {
    color: var(--modal-title);
    align-self: flex-start;
    font-size: 3em;
    margin-top: 1em;
  }

  .modal-links {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 200px;
  }

  .modal-link {
    text-decoration: none;
    margin: 1em 0;
  }

  .modal-item {
    list-style: none;
    text-align: center;
  }

  .modal-icon {
    color: var(--modal-icon-bg);
    font-size: 3em;
    text-align: center;
  }

  .modal-context {
    width: 100%;
  }

  .modal-subheader {
    color: var(--modal-subheader);
    align-self: flex-start;
    font-size: 2em;
    margin-bottom: 1em;
  }

  .description {
    color: var(--modal-text);
    margin: 0;
    align-self: flex-start;
    font-size: 1.4em;
  }

  .tech-stack {
    align-self: flex-start;
    padding: 0;
    font-size: 1.4em;
  }

  .tech-item {
    color: var(--modal-subheader);
    list-style-position: inside;
    align-self: flex-start;
  }
}
